export const removeDiacritics = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

// Upravená funkce pro navigaci
export const routeToUser = (player: any, router: any) => {
 // console.log('Navigace na profil hráče:', player);

  const userName = removeDiacritics(`${player.firstName} ${player.lastName}`)
    .toLowerCase()
    .replace(/\s+/g, '-'); // Převede mezery na pomlčky

 // console.log('Navigace na profil hráče:', `/users/${player._id}-${userName}`);
  router.push({
    path: `/users/${player._id}-${userName}`,
  });
};


export const linkOut = (url: string) => {
  const finalUrl = `https://www.${url}`;
  window.open(finalUrl, '_blank');
}