/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory } from 'vue-router/auto'
import { routes } from 'vue-router/auto-routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/pages/index.vue'),
      meta: {
        title: 'Modern Hockey - Hokejová platforma pro amatéry',
        description: 'Modern Hockey je platforma pro všechny amatérské hokejisty a hokejové nadšence. Získejte nejnovější informace o hokeji, trénincích a zápasech.',
      },
    },
    ...routes,  // Automatické routy z `pages`
    {
      path: '/users/:userId-:firstName-:lastName',  // Dynamická route pro uživatelský profil
      name: 'UserProfile',
      component: () => import('@/pages/users/userProfile.vue'),  // Ujisti se, že máš tento soubor ve složce `pages`
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // Vracíme uloženou pozici, pokud je dostupná (např. při navigaci zpět)
    if (savedPosition) {
      return savedPosition;
    } else {
      // Scroll na vrchol při navigaci na novou stránku
      return { top: 0 };
    }
  },
})

// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to) => {
  if (err?.message?.includes?.('Failed to fetch dynamically imported module')) {
    if (!localStorage.getItem('vuetify:dynamic-reload')) {
     // console.log('Reloading page to fix dynamic import error')
      localStorage.setItem('vuetify:dynamic-reload', 'true')
      location.assign(to.fullPath)
    } else {
      console.error('Dynamic import error, reloading page did not fix it', err)
    }
  } else {
    console.error(err)
  }
})


// Přidání beforeEach pro nastavení meta tagů
router.beforeEach((to, from, next) => {
  // Nastavení title
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) {
    // Type assertion to ensure TypeScript treats it as string
    document.title = nearestWithTitle.meta.title as string;
  }

  // Nastavení meta description
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.description);
  if (nearestWithMeta) {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      // Type assertion to ensure TypeScript treats it as string
      metaDescription.setAttribute('content', nearestWithMeta.meta.description as string);
    }
  }
  next();
});



router.isReady().then(() => {
  localStorage.removeItem('vuetify:dynamic-reload')
})



export default router
