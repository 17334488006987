<template>
  <v-app>
    <!-- Zobrazení MainMenu a Footer pouze pokud cesta není /register -->
    <MainMenu v-if="!hideLayout" />

    <router-view />


    <Footer v-if="!hideLayout" />


    <!-- Přidání LoginOverlay komponenty -->
    <LoginOverlay :show="appStore.isLoginVisible" @close="appStore.closeLoginOverlay" @login="handleLogin" />
    <PlayOverlay :show="appStore.isPlayRequestVisible" @close="appStore.closePlayRequest" @login="handlePlayClose" />
  </v-app>
</template>


<script lang="ts" setup>
import { onMounted, computed, ref, watch } from 'vue';
import { useAppStore } from './stores/app/appStore';
import { useUserStore } from '@/stores/user/userStore';
import { useRoute, useRouter } from 'vue-router';
import LoginOverlay from '@/components/LoginOverlay.vue'; // Import LoginOverlay komponenty
import PlayOverlay from '@/components/PlayOverlay.vue'; // Import LoginOverlay komponenty

const appStore = useAppStore();
const userStore = useUserStore();
const route = useRoute(); // Získání aktuální cesty
const router = useRouter(); // Získání routeru

const superliga = ref(route.query.superliga || null);

// Funkce pro zpracování přihlašovacích údajů
const handleLogin = (loginData: { email: string; password: string }) => {
  //console.log('Přihlášený uživatel:', loginData);
  appStore.closeLoginOverlay();
};

const handlePlayClose = (loginData: { email: string; password: string }) => {
  //console.log('Přihlášený uživatel:', loginData);
  appStore.closePlayRequest();
};

// Výpočetní vlastnost pro kontrolu, zda skrýt MainMenu a Footer
const hideLayout = computed(() => {
  const hideRoutes = ['/register', '/login', '/special-route', '/password'];
  return hideRoutes.includes(route.path);
});

// Sledování změn query parametrů
watch(() => route.query, (newQuery) => {
  superliga.value = newQuery.superliga || null;
  if(superliga.value){
    appStore.showPlayRequestOverlay();
  }
  console.log('Query parametry byly změněny:', newQuery);
});

onMounted(async () => {
  try {

    if ('serviceWorker' in navigator) {
      await navigator.serviceWorker.register('/service-worker.js');
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'navigate' && event.data.url) {
          router.push(event.data.url);
        }
      });
    }

    userStore.loadUserFromStorage(); // Načítání dat o uživateli při načtení aplikace
    appStore.connect();

  } catch (error) {
    console.error('Chyba při nastavování push notifikací:', error);
  }
});
</script>



<style>
.leaflet-bottom .leaflet-control {
  visibility: hidden;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}

.v-card.ice-card {
  border-radius: 15px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* Pro Safari */
  color: var(--v-mhblue-base);
}

.v-btn.corner-add-button {
  position: fixed !important;
  bottom: 10px !important;
  right: 20px;
  z-index: 1000;
}


html,
body,
#app {
  background-color: white;
  /* Nastavte barvu podle vašeho přání */
}

.minimal-select.v-field__input {
  padding: 0 !important;
  margin: 0 !important;
  height: 32px;
  /* Výška inputu */
  line-height: 32px;
  /* Vertikální centrování textu */
}

.vc-week{
  height: 50px;
}

.is-today{
  background-color: #f4f4f4;
  border-radius: 5px;
  border: 1px solid rgba(35,45,70,255) !important;
}

.vc-highlight-bg-solid{
  width: 35px;
  height: 35px;
}

.weekday-position-6, .vc-weekday-7{
  border-left: 1px solid rgb(225,225,225);
}

.vc-weekday{
  border-bottom: 1px solid rgb(225,225,225);
  background-color: rgb(250,250,250);
}

/* Nastavení maximální šířky obrázků v obsahu */
.article-content img {
    max-width: 100% !important;
    height: auto !important;
    width: auto !important; /* Přepíše pevnou šířku nastavenou Quill editorem */
}


.ql-align-center {
    text-align: center;
}
.ql-align-right {
    text-align: right;
}
.ql-align-left {
    text-align: left;
}

ol{
  padding-left: 20px;
  padding-right: 20px;
}
</style>
