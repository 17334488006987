// Funkce pro generování barvy avatara
export const getAvatarColor = (firstName: string, lastName: string) => {
    const fullName = `${firstName} ${lastName}`;
    let hash = 0;
    
    // Jednoduché hashování jména
    for (let i = 0; i < fullName.length; i++) {
      hash = fullName.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    // Převod hash hodnoty na hexadecimální barvu
    const color = '#' + ((hash >> 24) & 0xFF).toString(16).padStart(2, '0') +
                        ((hash >> 16) & 0xFF).toString(16).padStart(2, '0') +
                        ((hash >> 8) & 0xFF).toString(16).padStart(2, '0');
    
    return color;
  };