<template>
  <v-overlay :model-value="props.show" class="login-overlay" @click:outside="handleOutsideClick">
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="success" size="70" class="mb-4"></v-progress-circular>
    </div>

    <div v-else class="text-center">
      <div v-if="success">
        <v-icon size="100" color="success">mdi-check-circle-outline</v-icon>
        <h3 class="text-mhblue">Budeme pro tebe hledat tým...</h3>
      </div>
      <div v-else>
        <v-icon size="100" color="warning">mdi-alert-circle-outline</v-icon>
        <h3 class="text-mhblue">CHYBA - zkuste to později...</h3>
      </div>
    </div>
  </v-overlay>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useUserStore } from '@/stores/user/userStore';
import { useRouter, useRoute } from 'vue-router';
import { useAppStore } from '@/stores/app/appStore';
import axiosInstance from '@/services/axiosInstance'; // Import axios instance

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000';

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const appStore = useAppStore();

const loading = ref(false);
const success = ref(false);

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close']);

// Proměnná pro uchování ID timeoutu
let timeoutId: number | null = null;

// Funkce na vyčištění timeoutu
const clearTimeoutIfExists = () => {
  if (timeoutId !== null) {
    clearTimeout(timeoutId);
    timeoutId = null;
  }
};

// Funkce na zavření overlaye a odstranění parametru `superliga` z query
const handleClose = () => {
  clearTimeoutIfExists(); // Vyčisti timeout, pokud existuje
  emit('close');

  // Odstranění `superliga` z query parametrů
  const updatedQuery = { ...route.query };
  if ('superliga' in updatedQuery) {
    delete updatedQuery.superliga;
    router.replace({ path: route.path, query: updatedQuery }).catch((err) => {
      console.error('Chyba při aktualizaci URL:', err);
    });
  }
};

// Funkce volaná při kliknutí mimo overlay
const handleOutsideClick = () => {
  handleClose();
};

const updatePlayPreference = async () => {
  try {
    await axiosInstance.get(`${API_BASE_URL}/users/playleague`);
    success.value = true;
  } catch (error) {
    console.error('Chyba při ukládání dat:', error);
  }
};

const handleRequest = async () => {
  await updatePlayPreference();
  loading.value = false;
  // Nastav nový timeout
  timeoutId = window.setTimeout(() => {
    appStore.closePlayRequest();
    handleClose(); // Uzavření overlaye a odstranění parametru
  }, 3000);
};

// Watch pro sledování změn `props.show`
watch(() => props.show, async (newValue) => {
  if (newValue) {
    loading.value = true;
    clearTimeoutIfExists(); // Reset timeoutu, pokud byl aktivní

    if (!userStore.isAuthenticated) {
      appStore.showLoginOverlay();
    } else {
      handleRequest();
    }
  } else {
    clearTimeoutIfExists(); // Pokud je `show` vypnutý, zruš timeout
  }
});

// Watch pro sledování změn `userStore.isAuthenticated`
watch(() => appStore.showLogin, (newValue) => {
  if (!newValue && props.show) {
    if(userStore.isAuthenticated)
      handleRequest();
    else
    handleClose();
  }
});
</script>

<style scoped>
.v-overlay.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* Pro Safari */
  background-color: rgba(255, 255, 255, 0);
  z-index: 1000;
}
</style>
