/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'

// Utils
import { formatDate, formatDateForPicker } from '@/utils/dateUtils'
import { getAvatarColor } from '@/utils/userUtils'
import {removeDiacritics} from '@/utils/navigateUtils'
import 'quill/dist/quill.core.css' // Required
import 'quill/dist/quill.snow.css' // For snow theme (optional)
import 'quill/dist/quill.bubble.css' // For bubble theme (optional)

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

const app = createApp(App)

registerPlugins(app)
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.$formatDateForPicker = formatDateForPicker;
app.config.globalProperties.$removeDiacritics = removeDiacritics;
app.config.globalProperties.$getAvatarColor = getAvatarColor;

app.mount('#app')
