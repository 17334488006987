// plugins/vuetify.ts

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components, // Zaregistruje všechny komponenty Vuetify
  directives, // Zaregistruje všechny direktivy Vuetify
  theme: {
    defaultTheme: 'customDarkTheme', // Nastavíme vlastní výchozí téma
    themes: {
      customDarkTheme: {
        dark: true, // Definujeme, že se jedná o tmavé téma
        colors: {
          primary: '#1E88E5', // Definice primární barvy
          secondary: '#42A5F5', // Definice sekundární barvy
          accent: '#9C27B0', // Akcentní barva
          error: '#FF5252', // Barva pro chyby
          info: '#2196F3', // Barva pro informaci
          success: '#4CAF50', // Barva pro úspěch
          warning: '#FB8C00', // Barva pro varování
          background: '#121212', // Vlastní barva pozadí
          mhblue: 'rgba(35,45,70,255)', // Vlastní barva
          white: 'rgba(240,240,240,255)', // Vlastní barva
          mhred: 'rgba(163,10,54,255)', // Vlastní barva
          mhgold: '#BBA53D', // Vlastní barva
          listBackground: 'rgba(35,45,70,0.8)',
          firstLineColor: 'rgba(163,10,54,255)',
          secondLineColor: '#fb9124',
          thirdLineColor: '#74c476',
          gold: '#BBA53D',
          //mhtraining: 'red',
          //mhbeer: 'orange',
          //mhfriendlymatch: 'green',
          //mhsuperliga: 'black',
          //mhbluewhite: 'blue',
        },
      },
    },
  },
})
