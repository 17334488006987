import axios from 'axios';
import { useUserStore } from '@/stores/user/userStore';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000',
});

// Přidání interceptoru pro autorizaci
axiosInstance.interceptors.request.use((config) => {
  const userStore = useUserStore();
  const token = userStore.token;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});


// Interceptor pro odpovědi, který kontroluje nový token
axiosInstance.interceptors.response.use((response) => {
  const userStore = useUserStore();
  //console.log('Response headers:', response.headers); // Zde zkontroluj hlavičky
  //console.log('authorization headers:', response.headers['authorization'])
  const newToken = response.headers['authorization']?.split(' ')[1]; // Získání tokenu
  if (newToken) {
   // console.log('Nový token:', newToken); // Zde můžeš ověřit, že je token přítomen
    userStore.setToken(newToken);
  }
  return response;
}, (error) => {
  return Promise.reject(error);
});
export default axiosInstance;
