/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify'
import router from '../router'
import { createPinia } from 'pinia';
import { createHead } from '@vueuse/head';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import '../assets/styles/main.scss'


// Types
import type { App } from 'vue'

export function registerPlugins (app: App) {

  const pinia = createPinia();
  const head = createHead();
  pinia.use(piniaPluginPersistedstate);
  
  app
    .use(vuetify)
    .use(head)
    .use(router)
    .use(pinia) // Zarovnání na stejnou úroveň
    .use(VCalendar, {}); // Zarovnání na stejnou úroveň
}
