import { io, Socket } from 'socket.io-client';

export const useSocketService = (): Socket => {
  const socketUrl: string = import.meta.env.VITE_SOCKET_URL as string;
  
  // Při inicializaci připojení přidejte `auth` objekt
  const socket: Socket = io(socketUrl, {
    auth: {
      type: "intranet",
    },
    query: {
      //"my-key": "my-value",
    },
    withCredentials: false,
    transports: ["websocket"],
  });

  socket.on('connect', () => {
   // console.log('Connected to WebSocket server');
  });

  socket.on('disconnect', () => {
   // console.log('Disconnected from WebSocket server');
  });

  return socket;
};
