<template>
    <v-footer class="mh-blue-blur" style="min-height: 554px;">
        <v-container>
            <v-row justify="center" class="mb-12 align-center justify-center">
                <v-col cols="12">
                    <div class="partner title">Partneři</div>
                </v-col>
                <v-col cols="12" class="text-center pb-0 d-flex justify-center align-center text-center">
                    <v-img @click="linkOut('sportega.cz')" src="@/assets/partners/sportega.webp" class="partner-image"
                        alt="sportega.cz" height="150" />
                </v-col>
                <v-col cols="12" md="2" class="text-center pb-0 d-flex justify-center align-center text-center">
                    <v-img @click="linkOut('magnumwines.cz')" src="@/assets/partners/Magnum_wines_logo_mini.jpg"
                        alt="magnumwines.cz" class="partner-image" width="130" max-width="130" eager />
                </v-col>

                <v-col cols="12" md="4" class="text-center pb-0 d-flex justify-center align-center text-center">
                    <v-img @click="linkOut('rosterhockey.cz')" src="@/assets/partners/roster.webp" class="partner-image"
                        alt="rosterhockey.cz" width="300" max-width="300" eager />
                </v-col>

                <v-col cols="12" class="text-center pb-0">
                    <v-img @click="linkOut('opportunity.cz')" src="@/assets/partners/Opportunity_logo_mini.webp"
                        class="partner-image" alt="opportunity.cz" height="300" eager />
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="text-center pb-0">
                    <span @click="goToGdpr" class="ml-1 text-white href">Podmínky užití služby</span>
                </v-col>
                <v-col cols="12" class="text-center pt-0">
                    © {{ currentYear }} Modern Hockey
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { linkOut } from '@/utils/navigateUtils';

// Získání instance routeru
const router = useRouter();

// Funkce pro přesměrování na GDPR stránku
const goToGdpr = () => {
    router.push('/gdpr');
};

// Získání aktuálního roku
const currentYear = new Date().getFullYear();
</script>

<style scoped>
.v-footer {
    color: white;
    cursor: pointer;
}


.partner-image {

    border-radius: 5px;
}
</style>
