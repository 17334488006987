// Funkce pro formátování data, která vrací "dnes" pro dnešní datum a "zítra" pro zítřejší
export const formatDate = (date?: Date | string, dateTo?: Date | string): string => {
    if (!date) return ''; // Ověříme, zda existuje datum

    const parsedDate = new Date(date);
    const parsedDateTo = dateTo ? new Date(dateTo) : null;
    const now = new Date();
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); // Nastavíme zítřejší datum

    const isSameDay = (date1: Date, date2: Date): boolean => {
        return date1.getFullYear() === date2.getFullYear() &&
               date1.getMonth() === date2.getMonth() &&
               date1.getDate() === date2.getDate();
    };

    const daysOfWeek = ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'];
    const dayOfWeek = daysOfWeek[parsedDate.getDay()];

    // Podmínka pro "Do kabiny!" pokud je 30 minut před začátkem
    const thirtyMinutesBefore = new Date(parsedDate.getTime() - 30 * 60 * 1000);
    if (now >= thirtyMinutesBefore && now < parsedDate) {
        return 'Kabina!';
    }

    // Pokud je dateTo k dispozici a aktuální čas je mezi date a dateTo
    if (parsedDateTo && now >= parsedDate && now <= parsedDateTo) {
        return 'Nyní...';
    }

    // Pokud dateTo uplynulo
    if (parsedDateTo && now > parsedDateTo) {
        return 'Proběhla';
    }

    if (isSameDay(parsedDate, today)) {
        return 'Dnes';
    } else if (isSameDay(parsedDate, tomorrow)) {
        return 'Zítra';
    } else {
        return `${dayOfWeek} ${parsedDate.toLocaleDateString('cs-CZ', {
            day: 'numeric',
            month: 'numeric',
        })}`;
    }
};



// Funkce pro formátování datumu ve formátu YYYY-MM-DD
export const formatDateForPicker = (dateString: string | null) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Měsíc je 0-indexován
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
