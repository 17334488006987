<template>
  <v-overlay :model-value="props.show" class="login-overlay" @click:outside="handleOutsideClick">
    <transition name="slide-fade" appear>
      <v-card class="text-center py-12 px-4 puck-card centered-card" width="400"
        :style="{ backgroundColor: $vuetify.theme.themes.customDarkTheme.colors.listBackground }">
        <h3 class="nadpis">Přihlášení</h3>
        <v-form ref="loginForm">
          <v-text-field v-model="email" autocomplete="username" label="E-mail" :rules="emailRules" required
            class="mb-4"></v-text-field>

          <v-text-field v-model="password" @keydown.enter="submitLogin" autocomplete="current-password" label="Heslo"
            :rules="passwordRules" type="password" required class="mb-4"></v-text-field>

          <v-btn size="small" rounded @click="submitLogin" :disabled="!formIsValid || isLoading"
            :color="$vuetify.theme.themes.customDarkTheme.colors.mhred">
            <template v-if="isLoading">
              <v-progress-circular indeterminate size="20" color="white" class="mr-2"></v-progress-circular>
              Přihlašuji...
            </template>
            <template v-else>
              Přihlásit se
            </template>
          </v-btn size="small">
          <div style="margin-top: 5px;">
            <v-btn @click="routeToRegister()" v-if="!errorMessage" size="small" variant="text">
              <span class="href">Nemáte registraci?</span>
            </v-btn>
          </div>
          <div @click="renewPassword" v-if="errorMessage === 'Nesprávné heslo.'" class="href mt-1">Obnovit heslo</div>
        </v-form>
      </v-card>
    </transition>
    <v-alert v-if="errorMessage" type="error" class="mt-4">
      {{ errorMessage }}
    </v-alert>
  </v-overlay>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useUserStore } from '@/stores/user/userStore';
import { useRouter } from 'vue-router';
import { useAppStore } from '@/stores/app/appStore';

const userStore = useUserStore();
const router = useRouter();
const appStore = useAppStore();

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000';

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close']);

const email = ref('');
const password = ref('');
const formIsValid = ref(false);
const isLoading = ref(false);
const errorMessage = ref<string | null>(null);

const emailRules = [
  (v: string) => !!v || 'E-mail je povinný',
  (v: string) => /.+@.+\..+/.test(v) || 'E-mail musí být platný',
];
const passwordRules = [
  (v: string) => !!v || 'Heslo je povinné',
  (v: string) => v.length >= 8 || 'Heslo musí mít alespoň 8 znaků',
];

const validateForm = () => {
  const emailValid = emailRules.every((rule) => rule(email.value) === true);
  const passwordValid = passwordRules.every((rule) => rule(password.value) === true);
  formIsValid.value = emailValid && passwordValid;
};

const clearForm = () => {
  email.value = '';
  password.value = '';
  isLoading.value = false;
  formIsValid.value = false;
  errorMessage.value = null;
};

const routeToRegister = () => {
  clearForm();
  emit('close');
  if (appStore.showPlayRequest)
    router.push('/register?playleague=true');
  else
    router.push('/register');
};

const renewPassword = () => {
  clearForm();
  emit('close');
  router.push({
    path: '/password',
    query: { email: email.value } // Přidáno jako query parametr
  });
};

watch([email, password], validateForm);

const submitLogin = async () => {
  if (formIsValid.value) {
    isLoading.value = true;
    errorMessage.value = null;

    try {
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.value.trim().toLowerCase(),
          password: password.value,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        userStore.setUser(result);
        clearForm();
        emit('close');
      } else {
        errorMessage.value = result.message || 'Chyba při přihlášení. Zkontrolujte své údaje.';
      }
    } catch (error) {
      errorMessage.value = 'Chyba při připojení k serveru.';
    } finally {
      isLoading.value = false;
    }
  } else {
    errorMessage.value = 'Formulář není validní, vyplňte správně všechna pole.';
  }
};


const handleOutsideClick = () => {
  clearForm();
  emit('close');
};
</script>

<style scoped>
.nadpis {
  margin-top: -30px;
}

.v-overlay.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* Pro Safari */
  background-color: rgba(255, 255, 255, 0);
  z-index: 1000;
}

.puck-card {
  width: 320px !important;
  height: 320px !important;
  background-color: black !important;
  border: 3px solid rgb(74, 73, 73);
  border-radius: 50%;
  padding: 0px;
  z-index: 1000;
  box-shadow: 10px 20px 10px rgba(0, 0, 0, 0.4);
}

.centered-card {
  margin: 0 auto;
}

.href {
  cursor: pointer;
  text-decoration: underline;
}



:deep(.slide-fade-enter-active),
:deep(.slide-fade-leave-active) {
  transition: all 0.7s ease;
}

:deep(.slide-fade-enter-from) {
  transform: translateX(-100%);
  opacity: 0;
}

:deep(.slide-fade-enter-to) {
  transform: translateX(0);
  opacity: 1;
}

:deep(.slide-fade-leave-from) {
  transform: translateX(0);
  opacity: 1;
}

:deep(.slide-fade-leave-to) {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
